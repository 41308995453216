<template>


      <div class="nav-scroller py-1 mb-4">

        <nav class="nav d-flex justify-content-between mb-5" id="navScroller">
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin'}" v-bind:to="'/admin'">Active</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin/ended'}" v-bind:to="'/admin/ended'">Ended</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin/users'}" v-bind:to="'/admin/users'">Users</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin/add-listing'}" v-bind:to="'/admin/add-listing'">Add Listing</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin/add-winner'}" v-bind:to="'/admin/add-winner'">Add Winner</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/update-details'}" v-bind:to="'/admin/update-details'">Personal Details</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/admin/transactions'}" v-bind:to="'/admin/transactions'">Transactions</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path == '/admin/change-password'}" v-bind:to="'/dashboard/change-password'">Change Password</router-link>
          <router-link class="p-2 logout-link" v-bind:to="'/logout/'">Logout</router-link>
        </nav>

      </div>

</template>

<script>
import Api from '../../scripts/api'
export default {
  props: {
    selected:Number
  },
  data() {
    return {
      joinedActive: 0
    }
  },
  async created() {
    var result = await Api.competition.listUserActive();
    if(result.status == 200) {
      this.joinedActive = (await result.json()).length;
    }
  }
}
</script>