<template>

  <section id="dashboard" class="mt-4">

    <div class="container">

        <div class="row mt-5">

            <SideNav v-bind:selected="6"/>

            <div class="col-sm-12 col-md-8 mt-sm-3 mt-md-0">
                <div class="row">
                    <div class="col-4">
                      <span class="heading">Personal Details</span>
                    </div>
                </div>
                <form id="payment_method" class="mt-2">

                    <input id="old_password" type="password" v-model="oldPassword" name="old_password" placeholder="Old Password"/>
                    <div class="mb-2"></div>
                    <input id="password" type="password" v-model="newPassword" name="password" placeholder="New Password"/>
                    <div class="mb-2"></div>
                    <input id="confirm_password" v-model="newPasswordRepeat" type="password" name="confirm_password" placeholder="Confirm Password"/>

                  <div class="total mt-3">



                    <div class="row mt-3">
                      <div class="col">

                      </div>
                      <div class="col">
                        <button v-on:click="ChangePassword" type="button" class="btn btn-custom mt-3">Save</button> 
                      </div>
                    </div>



                  </div>
                </form>
            </div>
        </div>
    </div>
  </section>


  <Footer/>
</template>

<script>

import Api from '../../scripts/api'

import Footer from '../../components/global/Footer'
import SideNav from '../../components/admin/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    },
    data() {
      return {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      }
    },
    methods: {
      ChangePassword: async function() {
        if(this.newPassword != this.newPasswordRepeat) return alert('New password does not match!');
        var result = await Api.changePassword(this.oldPassword, this.newPassword);
        if(result.status == 422) return alert('The new password does not meet required parameters, try another password.');
        if(result.status == 401) return alert('Unauthorized request, log out and try again.');
        if(result.status == 403) return alert('Old password is invalid.');
        if(result.status == 200) {
          return alert('Password changed successfully!');
        }
      }
    }
}
</script>